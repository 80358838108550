import { dateFilter } from '@/shared/filters/ToDate'

export default {
  name: 'NewRegisterTeacher',
  props: {
    placeholder: String,
    title: String,
    teacher: Object,
    isNew: Boolean,
  },

  data: () => ({
    genders: [
      { id: 0, text: 'Masculino' },
      { id: 1, text: 'Feminino' }
    ],
    dateFormatted: null
  }),

  created() {

  },

  watch: {

  },

  computed: {
    state() {
      if (this.teacher && this.teacher.name) {
        return this.teacher.name.length >= 6
      }
    },
    disable() {     
      if (this.isNew === true) {
        if ((!this.teacher.gender && !this.teacher.birthDate))
          return true
        else {
          if (this.teacher.documentRG && this.teacher.documentCPF && this.teacher.name)
            return this.teacher.name.length < 6
        }

      } else {
          if (this.teacher.documentCPF && this.teacher.name && this.teacher.birthDate) {
            return this.teacher.name.length < 6
        }
      }
      return true
    }
  },

  methods: {
    backTo() {
      this.$router.go(-1)
    },

    save() {
      this.isNew 
        ? this.$emit('create', this.teacher)
        : this.$emit('update', this.teacher)
    },

    onContext(ctx) {      
      this.dateFormatted = ctx.selectedFormatted
    },

    formatDate(event) {
      this.teacher.birthDate = dateFilter(event.currentTarget.value)
    }
  }
}