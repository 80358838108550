import TeacherRepository from '@/shared/http/repositories/socialProject/teacher'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import NewRegisterTeacher from '@/components/layout/NewRegisterTeacher/newRegisterTeacher.vue'
import Teacher from '@/shared/models/teacher'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading, toast } from '@/shared/utils/UIHelper'

export default {
  name: 'NovoProfessor',

  components: {
    [BreadCrumb.name]: BreadCrumb,
    [NewRegisterTeacher.name]: NewRegisterTeacher
  },

  data: () => ({
    placeholder: 'Nome',
    title: 'Professor',
    teacher: new Teacher(),
    isNew: true
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Professores',
            href: this.$router.resolve({ name: 'Teacher' }).href
          },
          {
            text: 'Novo professor',
            active: true
          }
        ]
      }
    }
  },

  methods: {
    create(teacher) {
      loading.push()
      TeacherRepository.Create(teacher)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          loading.pop()
          toast.success('Professor criado com sucesso', 'NOVO PROFESSOR')
          Promise.resolve()
          return this.$router.replace({ name: 'Teacher' })
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao criar professor', 'ERRO')
        })
    }
  }
}
