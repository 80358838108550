import GuidUtils from '@/shared/utils/guid'

export default class Teacher {
  constructor({
    id,
    name,
    birthDate,
    cityId,
    code,
    companyId,
    complement,
    contacts,
    district,
    documentBirth,
    documentCPF,
    documentRG,
    gender,

  } = {}) {
    this.id = id || GuidUtils.emptyGuid,
    this.name = name,
    this.birthDate = birthDate,
    this.cityId = cityId,
    this.code = code,
    this.companyId = companyId,
    this.complement = complement,
    this.contacts = contacts,
    this.district = district,
    this.documentBirth = documentBirth,
    this.documentCPF = documentCPF,
    this.documentRG = documentRG,
    this.gender = gender
  }
}